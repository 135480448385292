<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ສະຫຼຸບຍອດຂາຍໃຫ້ສາຂາຕົວແທນ</h2></v-col>
      <v-col class="al-end">
        <v-btn color="primary"
               @click="exportData"
               :loading="exportLoading"
               :disabled="exportLoading">Export
        </v-btn>
      </v-col>

    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row>
      <v-col cols="3">
        <v-menu
            v-model="start_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="start_date"
                label="ເລີ່ມວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="start_date"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-menu
            v-model="end_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="end_date"
                label="ຫາວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="end_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex" cols="3">
        <v-select
            v-model="selectedStatus"
            :items="statuses"
            label="ສະຖານະ"
            item-text="name"
            item-value="value"
            dense
            outlined
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="3">
        <v-select
            v-model="selectedBranch"
            :items="filteredItems"
            label="ເລືອກສາຂາ"
            item-text="name"
            item-value="id"
            dense
            outlined
        ></v-select>
      </v-col>
      <!--
      <v-col class="d-flex" cols="3">
        <v-select
          v-model="selectedUser"
          :items="users"
          label="User"
          item-text="name"
          item-value="id"
          dense
          outlined
          @input="fetchBranchInvoice()"
        ></v-select>
      </v-col>
  -->
    </v-row>

    <v-row class="mb-1">
      <v-col cols="3">
        <h4>ລວມບິນ: {{ Intl.NumberFormat().format(totalBill) }}</h4>
      </v-col>
      <v-col cols="3">
        <h4>ລວມສາຂາ: {{ Intl.NumberFormat().format(totalBranch) }}</h4>
      </v-col>
      <v-col cols="3">
        <h4>ລວມຕຸກນ້ຳ: {{ Intl.NumberFormat().format(sumTotalBottles) }}</h4>
      </v-col>
      <v-col class="3">
        <h4>ລວມເງິນທັງໝົດ: {{ Intl.NumberFormat().format(sumTotalPrice) }}</h4>
      </v-col>

    </v-row>
    <!-- <hr /> -->
    <v-data-table
        :headers="headers"
        :items="branchInvoices"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
    >
      <template v-slot:item.total="{ item }">
        {{ Intl.NumberFormat().format(item.total) }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format("YYYY-MM-DD hh:mm:ss") }}
      </template>
      <template v-slot:item.success_at="{ item }">
        {{ moment(item.success_at).format("YYYY-MM-DD") }}
      </template>
      <template v-slot:item.total_discount="{ item }">
        {{ Intl.NumberFormat().format(item.total_discount) }}
      </template>
      <template v-slot:item.subTotal="{ item }">
        {{ Intl.NumberFormat().format(item.subTotal) }}
      </template>
      <!--      <template v-slot:item.team="{ item }">-->
      <!--        <div class="primary-color">{{ item.createBy.name }}</div>-->
      <!--        <span v-for="(member, index) in item.createByMembers" :key="index">-->
      <!--          <span>{{ member.name }}, </span>-->
      <!--        </span>-->
      <!--      </template>-->
      <template v-slot:item.status="{ item }">
        <v-chip color="success">{{ item.status }}</v-chip>
      </template>
      <!--      <template v-slot:item.payment_type="{ item }">-->
      <!--        <div :class="getPaymentTypeColor(item.payment_type)">-->
      <!--          {{ paymentType(item.payment_type) }}-->
      <!--        </div>-->
      <!--      </template>-->
      <!--Action -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="viewInvoice(item.id)"
        >mdi-eye
        </v-icon
        >
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
    </v-data-table>
    <br/>
    <template>
      <Pagination
          v-if="pagination.last_page > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchBranchInvoice()"
      ></Pagination>
    </template>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      start_menu: false,
      end_menu: false,

      loading: false,
      bankLoading: false,
      TableLoading: false,
      viewModalBranch: false,
      exportLoading: false,
      invoiceId: "",
      branchInvoices: [],
      invoiceDetailItem: [],
      invoiceDetail: {},
      customerDetail: {},
      createdByDetail: {},
      branches: [],

      selectedBranch: "all",
      search: "",
      type: "branch",
      users: [],
      selectedUser: "all",
      //Sum Variable
      totalBill: 0,
      sumTotalPrice: 0,
      sumTotalCash: 0,
      sumTotalBottles: 0,
      totalBranch: 0,
      //Payment Type
      selectedStatus: "all",
      selectedPayment: "all",
      //Branch default value
      defaultBranchValue: {
        id: "all",
        name: "ທັງໝົດ",
      },
      statuses: [
        {
          value: "all",
          name: "ທັງໝົດ",
        },
        {
          value: "pending",
          name: "ລໍຖ້າ",
        },
        {
          value: "success",
          name: "ສຳເລັດ",
        }, {
          value: "failed",
          name: "ລົ້ມເຫຼວ",
        },
      ],
      payment_Types: [
        {
          value: "all",
          name: "ທັງໝົດ",
        },
        {
          value: "cash",
          name: "ເງິນສົດ",
        },
        {
          value: "bank",
          name: "ທະນາຄານ",
        },
        {
          value: "support",
          name: "ສະໜັບສະໜູນ",
        },
        {
          value: "debt",
          name: "ຕິດໜີ້",
        },
      ],
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 70,
      headers: [
        {
          text: "ເລກບິນ",
          align: "start",
          sortable: false,
          value: "invoice_number",
          width: "80"
        },
        {
          text: "ສາຂາ",
          align: "start",
          sortable: false,
          value: "branch_name",
          width: "180"
        },
        {text: "ຈຳນວນ", align: "center", value: "qty", width: "100"},
        {text: "ລວມ", value: "sub_total", width: "100"},
        {text: "ສ່ວນຫຼຸດ", value: "total_discount", width: "120"},
        {text: "ລວມເງິນ", value: "total", width: "120"},
        // { text: "ຊຳລະ", value: "payment_type", sortable: false },
        {text: "ວັນທີຂາຍ", value: "created_at", width: "120"},
        {text: "ວັນທີຊຳລະ", value: "success_at", width: "120"},
        {text: "ສະຖານະ", value: "status", width: "120"},
        // { text: "ຜູ້ອອກບິນ", value: "team", align: "start", sortable: false,width: "90" },
        {text: "", value: "actions", sortable: false, align: "center"},
      ],
      editedIndex: -1,
    };
  },
  metaInfo() {
    return {
      title: `ສະຫຼຸບຍອດຂາຍ - ທັງໝົດ`,
      meta: [
        {name: 'description', content: 'ສະຫຼຸບຍອດຂາຍ - ທັງໝົດ'},
      ]
    }
  },

  methods: {
    fetchBranchInvoice() {
      this.TableLoading = true;
      this.$axios
          .post("admin/report-invoice-branches", {
            "branch": this.selectedBranch,
            "status": this.selectedStatus,
            // created_by: this.selectedUser,
            start_date: this.start_date,
            end_date: this.end_date,
            page: this.pagination.current_page,
            per_page: this.per_page,
          })
          .then((res) => {
            setTimeout(() => {
              this.branchInvoices = res.data.data.data;
              this.pagination = res.data.data;
              this.totalBill = res.data.total_bill;
              this.sumTotalPrice = res.data.total_mony;
              this.totalBranch = res.data.total_branch;
              this.sumTotalBottles = res.data.total_amount;
              this.start_menu = false;
              this.end_menu = false;
              this.TableLoading = false;
            }, 100);
          })
          .catch(() => {
            this.TableLoading = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    listBranch() {
      this.$admin.get('list-branch').then(res => {
        this.branches = res.data.branches;
        this.branches.unshift(this.defaultBranchValue);
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
      //
      // this.$axios
      //     .get("list-branches")
      //     .then((res) => {
      //       setTimeout(() => {
      //         this.branches = res.data.branches;
      //         this.branches.unshift(this.defaultBranchValue);
      //         this.TableLoading = false;
      //         this.listUser();
      //       }, 100);
      //     })
      //     .catch(() => {
      //       this.TableLoading = false;
      //       this.$store.commit("Toast_State", this.toast_error);
      //     });
    },
    viewInvoice(value) {
      let route = this.$router.resolve({name: 'ReportBranchInvoiceDetail', params: {id: value}});
      window.open(route.href, '_blank');
    },
    //Confrim Payment Type
    paymentType(value) {
      if (value == "debt") return "ຕິດໜີ້";
      else if (value == "cash") return "ເງິນສົດ";
      else if (value == "bank") return "ທະນາຄານ";
      else if (value == "support") return "ສະໜັບສະໝູນ";
      else return;
    },
    getPaymentTypeColor(value) {
      if (value == "bank") return "success--text";
      else if (value == "cash") return "primary--text";
      else if (value == "debt") return "error--text";
      else if (value == "support") return "purple--text";
    },
    saleType(sale, price) {
      if (sale == "sale") return Number(price).toLocaleString();
      else if (sale == "free") return "Free";
    },
    getFreeStyle(sale) {
      if (sale == "free") return "primary-color";
    },
    totalPay(b, total, discount) {
      return Number(b + total - discount).toLocaleString();
    },

    //Export excel
    exportData() {
      this.exportLoading = true;
      this.$admin
          .post(
              "export-invoice-franchises",
              {
                "branch": this.selectedBranch,
                "status": this.selectedStatus,
                "start_date": this.start_date,
                "end_date": this.end_date,
              },
              {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute("download", "report-branch-sale " + ".xlsx");
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$store.commit("Toast_State", this.toast_error);
            }
            this.exportLoading = false;
          });
    },
  },

  computed: {
    filteredItems() {
      return this.branches.filter(item => item.type === 'franchise')
    }
  },

  watch: {
    selectedBranch: function () {
      this.pagination.current_page = '';
      this.fetchBranchInvoice();
    },
    selectedStatus: function () {
      this.pagination.current_page = '';
      this.fetchBranchInvoice();
    },
    selectedPayment: function () {
      this.pagination.current_page = '';
      this.fetchBranchInvoice();
    },
    start_date: function () {
      this.pagination.current_page = '';
      this.fetchBranchInvoice();
    },
    end_date: function () {
      this.pagination.current_page = '';
      this.fetchBranchInvoice();
    },
  },
  created() {
    this.listBranch();
    this.fetchBranchInvoice();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}

.invoice {
  font-size: 16px;
  color: #000000;
}

.primary-color {
  color: #00c1d2;
}
</style>
